import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

import "../../styles/proyectos-propios.scss"
import ProyectosPropios from "../../images/proyectos_propios_nf.png"
import InsulaLiving from "../../images/logo_insula_living.jpg"
import VitaHabitare from "../../images/vita-habitare.png"
import InsulaCristales from "../../images/insula_cristales.png"
import Mussa from "../../images/mussa.jpg"
import theC from "../../images/the-c.png"
import Grandi from "../../images/grandi.jpg"
import Vibes from "../../images/vibes.jpg"

export default function Gestion({ children, location }) {
  return (
    <Layout currentPath={location}>
      <SEO title="Proyectos | Proyectos" />
      <div className="proyectos-propios">
        <Link to="/#proyectos" className="back">
          &larr;Volver
        </Link>
        <div className="proyectos-propios__title">
          <div className="proyectos-propios__texture" />
          <h1>PROYECTOS PROPIOS</h1>
        </div>
        <div className="proyectos-propios__content">
          <div className="proyectos-propios__content__leftbar" />
          <img
            className="proyectos-propios__logo"
            src={ProyectosPropios}
            alt="Grupo Ínsula Proyectos Propios"
          />
          <p>
            Nuestros proyectos están pensados para una generación que ya está
            viviendo un mundo completamente conectado, no solo en tecnología e
            innovación sino también con el medio ambiente. Por eso, además de
            diseño y funcionalidad, nuestros proyectos se desarrollan buscando
            siempre un equilibrio con la naturaleza.
          </p>
          <div className="proyectos-propios__content__rightbar" />
        </div>
        <div className="proyectos-propios__projects-container">
          <div className="left">
            <img src={InsulaLiving} alt="Insula Living Juananbú" />
            <div className="text-container">
              <p>
                Desde 20 a 67 m2 en el oeste de Cali, AIRBNB Friendly, un
                proyecto que te conecta con más de 13 zonas sociales.
              </p>
            </div>
            <div className="buttons-container">
              <div className="vendido">Vendido</div>
              <Link to="/proyectos/insula-living">Ver más</Link>
            </div>
          </div>

          {/* <div className="right">
            <img src={VitaHabitare} alt="Insula Vita Habitare" />
            <div className="text-container">
              <p>
                73 apartaestudios al Oeste de Cali donde la ciudad y la
                naturaleza se encuentran en armonía
              </p>
            </div>
            <a href="https://vita.habitare.com.co/" target="_blank">
              Ver más
            </a>
          </div> */}

          {/* <div className="left">
            <img src={InsulaCristales} alt="Insula Living Juananbú" />
            <div className="text-container">
              <p>Un proyecto que innovará el oeste de Cali.</p>
            </div>
            <a href="https://habitare.com.co/" target="_blank">
              Ver más
            </a>
          </div> */}

          <div className="right">
            <img src={theC} alt="The C" />
            <div className="text-container">
              <p>Ubicado en el sector de Centenario, de la ciudad de Cali.</p>
            </div>
            <a href="https://www.upconstructores.com/" target="_blank">
              Ver más
            </a>
          </div>

          <div className="left">
            <img src={Grandi} alt="Grandi" />
            <div className="text-container">
              <p>
                Comunidad auténtica. 92 apartamentos doble altura con un nuevo
                concepto de vivienda en el oeste de Cali.
              </p>
            </div>
            <a href="https://grandi.grupoinsula.com/" target="_blank">
              Ver más
            </a>
          </div>

          {/* <div className="right">
            <img src={Mussa} alt="Mussa" />
            <div className="text-container">
              <p>
                Próximamente lotes residenciales desde 500M2, que traerán un
                panorama de ensueño
              </p>
            </div>
            <div className="proximamente">Próximamente</div>
          </div> */}
        </div>
      </div>
    </Layout>
  )
}
